export const soon = {
  title: "Come Back Later...",
  body: `<br />
    <h1>Hi, you come to early</h1>
    <br /><br /><br />
    <p>
        I know this page is very interesting for you, especially for your special day but.<br />
        You need to be patience until the time has come, right ?
    </p>`,
};

export const late = {
  title: "See you next time...",
  body: `<br />
    <h1>The party was over</h1>
    <br /><br /><br />
    <p>
        Yes, my gift for you is kinda simple, cheap, and weird ? &#128534<br>
        B-but. It's only for you. &#128150
    </p>
`,
};
